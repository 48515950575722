import React from "react"
import {Link} from "gatsby"

import styled from 'styled-components'

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import TopSection from "../components/sections/TopSection";

const IndexPage = () => (
    <Layout>
        <SEO title="ШАГИ"/>
        <Wrapper>
            <TopSection/>
        </Wrapper>


    </Layout>
)

export default IndexPage

const Wrapper = styled.div`
    font-family: Avenir Next Cyr;
    overflow: hidden;
`;
