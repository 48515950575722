import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';

const bgImage = require('./images/banner.jpg')

export default function TopSection(props) {


    return (
        <Wrapper>

            <TopHeader>
                <LogoImg src={require('./images/logo.svg')}/>
                <LinksPlaceholder>
                    <LinkItem>
                        для кого
                    </LinkItem>
                    <LinkItem>
                        как это работает
                    </LinkItem>
                    <LinkItem>
                        что предлагаем
                    </LinkItem>
                    <LinkItem>
                        о нас
                    </LinkItem>
                    {/*<LinkItem>*/}
                    {/*для тренеров*/}
                    {/*</LinkItem>*/}
                    <SignUpItem onClick={() => {
                        window.location.href = `/app/`;
                    }}>
                        регистрация
                    </SignUpItem>
                </LinksPlaceholder>
            </TopHeader>

            <ContentPlaceholder>
                <ContentInner>
                    <MainMottoPlaceholder>
                        <MainMotto>
                            УЧИМ ШАХМАТАМ,
                            <br/>
                            ОНЛАЙН
                        </MainMotto>
                        <BottomButtonPlaceholder>
                            <BlackButton onClick={() => {
                                window.location.href = `/app/`;
                            }}>
                                ХОЧУ ПОПРОБОВАТЬ
                            </BlackButton>
                        </BottomButtonPlaceholder>
                    </MainMottoPlaceholder>
                </ContentInner>
            </ContentPlaceholder>

        </Wrapper>
    );
}

const ContentPlaceholder = styled.div`
    height: calc(100vh - 180px);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;

const ContentInner = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    width: 1100px;
    height: 100%;
`;

const MainMottoPlaceholder = styled.div`
    
`;

const MainMotto = styled.div`
    margin-bottom: 52px;
    font-size: 65px;
    color: black;
    line-height: 80px;
    font-weight: 800;
`;

const LinkItem = styled.div`
      font: 600 16px/1.56 "Open Sans",sans-serif;
      font-weight: bold;
      color: black;
      margin-right: 20px;
      text-transform: uppercase;
      cursor: pointer;
      box-sizing: border-box;
      border-bottom: 2px dashed transparent;
      :hover{
        border-bottom: 2px dashed black;
      }
      @media(max-width: 1100px){
        font: 600 14px/1.56 "Open Sans",sans-serif;
      }
`;

const SignUpItem = styled.div`
    font: 600 16px/1.56 "Open Sans",sans-serif;
    font-weight: bold;
    box-sizing: border-box;
    border: 3px solid black;
    border-radius: 10px;
    padding-left: 24px;
    padding-right: 24px;
    height: 50px;
    line-height: 44px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    color: black;
    cursor: pointer;
    margin-left: 20px;
    :hover{
      background: white;
    }
`;

const LinksPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
`;

const Wrapper = styled.div`
    width: 100vw;
    height: calc(100vh - 50px);
    box-sizing: border-box;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(${bgImage});
    font-family: "Open Sans",sans-serif;
`;

const TopHeader = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 1100px;
    box-sizing: border-box;
    margin: 0 auto;
    padding-top: 25px;
    @media(max-width: 1100px){
      width: 100%;
    }
`;

const LogoImg = styled.img`
    height: 45px;
    margin-bottom: 0px;
`;

const BottomButtonPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 30px;
`;

const BlackButton = styled.div`
    background: black;
    height: 50px;
    border-radius: 4px;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-left: 30px;
    padding-right: 30px;
    cursor: pointer;
    :hover{
      opacity: 0.9;
    }
`;
